import { Link } from "react-router-dom";
// import SectionTitle from "../components/SectionTitle";
import Card from "./Card";
const ProductSection = ({ combinedData }) => {
  console.log(combinedData);
  return (
    <>
      {combinedData?.map(
        (category) =>
          category?.featured === 1 && (
            <section
              key={category?.id}
              className="product-section container mx-auto px-3"
            >
              <div className="header-section border-b-4 mt-10 mb-5 border-black flex justify-between items-center">
                <h2 className="title text-3xl">{category?.name}</h2>

                <Link
                  to={`/categorypage/category/${category?.id}`}
                  className="font-semibold text-xl"
                >
                  View All
                </Link>
              </div>
              <div className="products-container gap-5 flex flex-wrap justify-center  ">
                {category?.products
                  ?.slice(0, 8)
                  .map(
                    (product) =>
                      product && <Card key={product?.id} product={product} />
                  )}
              </div>
            </section>
          )
      )}
    </>
  );
};

export default ProductSection;
